import store from '@sb/store'
import UserInformation from './user_information.js'
const sb = require('@sb/util')
import UserTraces from './user_traces.js'
import UserTasks from './user_tasks.js'
import UserNotes from './user_notes.js'
import UserOrders from './user_orders.js'
import UserConversations from './user_conversations.js'
import UserLabels from './user_labels.js'
import UserLeadOwner from './user_lead_owner.js'
import UserRelatedProfiles from './user_related_profiles.js'
import UserTickets from './user_tickets.js'

export default {
	name: 'user-profile',
	props: ['ucid', 'customer_header', 'dont_auto_select_convo', 'call_entry'],
	data() {
		return {
			refresh: false,
			retrying: false,
			error: false,

			userid: '',
			convoid: '',
			trueUserId: '',
		}
	},

	watch: {
		ucid() {
			this.error = false
			this.retrying = false
			//this.userid = ''
			//this.convoid = ''
			this.resolveUcid()
		},
	},

	mounted() {
		this.$root.$on('scrollConvoListIntoView', () => {
			this.$refs.user_conversation_history.scrollIntoViewIfNeeded()
		})
		this.resolveUcid()
		store.onConvo(this, async (convoM) => {
			if (!convoM[this.convoid]) return
			this.refresh = !this.refresh
		})

		store.onUser(this, async (userM) => {
			let userid = (this.ucid || '').split('%')[0]
			//if (!userM[this.userid]) return
			if (!userM[userid]) return
			let trueUser = store.matchUser(userid, true)
			if (trueUser) {
				// stop loading when has user in store
				this.userid = lo.get(trueUser, 'primary_id') || userid
				this.trueUserId = userid
			}
			this.refresh = !this.refresh
		})
	},

	methods: {
		async resolveUcid() {
			let iid = this._resolveid || 0
			iid++
			this._resolveid = iid

			//this.userid = ''
			let ucid = this.ucid || ''
			let userid = ucid.split('%')[0]
			this.convoid = ucid.split('%')[1]
			this.trueUserId = userid

			// dont use this logic anymore. activities.js will resolve ucid insteead
			//if (!userid || userid == '-') {
			//// select the first user but only when we are not creating conversation
			//if (this.convoid && !this.convoid.startsWith('create')) {
			//let convo = store.matchConvo(this.convoid)
			//if (!convo) {
			//await store.fetchConvos([this.convoid])
			//if (iid != this._resolveid) return // outdated
			//convo = store.matchConvo(this.convoid)
			//if (!convo) {
			//this.error = true
			//return
			//}
			//}
			//this.userid = sb.usersOfConvo(convo)[0]
			//if (!this.userid) {
			//this.error = true
			//return
			//}
			//return this.$emit('ucidChange', this.userid, this.convoid)
			//}
			//}

			//await store.fetchUser(userid)
			//if (iid != this._resolveid) return // outdated
			let user = store.matchUser(userid, true)
			if (!user) {
				await store.fetchUser(userid, true)
				// try to tell user_information rerender with fully info
				this.refresh = !this.refresh
				if (iid != this._resolveid) {
					return // outdated
				}
				user = store.matchUser(userid, true)
			}

			if (!user && userid && userid != '-') {
				this.error = true
				return
			}
			if (user && user.primary_id) this.userid = user.primary_id
			else this.userid = userid
		},

		async onRetryLoadUser() {
			if (this.retrying) return
			let ucid = this.ucid || ''
			let userid = ucid.split('%')[0]
			this.trueUserId = userid
			let uid = this.userid
			this.retrying = true
			await sb.sleep(500)
			if (uid != this.userid) return // outdated
			if (!uid || uid == '-') {
				this.retrying = false
				return
			}
			await store.fetchUser(uid, true)
			if (uid != this.userid) return // outdated
			let user = store.matchUser(this.userid, true)
			if (user && user.primary_id) {
				this.userid = user.primary_id
			}
			if (user) this.error = false
			this.retrying = false
		},

		convoSelect(cid, options = {mounted: false}) {
			let convo = store.matchConvo(cid) || {}
			let userids = sb.usersOfConvo(convo)
			let uid = userids[0]

			// try to detect convo has true_user_id, sometime email convo has 2 user member, so 1st user is not true user
			if (uid !== this.trueUserId) {
				let mem = lo.find(convo.members, (member) => member.id === this.trueUserId)
				if (mem) uid = this.trueUserId
			}
			//let user = store.matchUser(uid)
			this.$emit('ucidChange', uid, cid, false, options.mounted)
		},

		onTicketSelet(ticketid) {
			this.$emit('ticketSelect', ticketid)
		},

		renderError() {
			let $padder = <div style='width: 1000px'></div>
			let $retry = (
				<a href='#' vOn:click={this.onRetryLoadUser}>
					{this.$t('retry')}
				</a>
			)
			if (this.retrying) $retry = <Spinner class='ml-2' mode='blue' />
			return (
				<div class='profile'>
					{$padder}
					<div style='margin-top: 200px; display: flex; align-items: center; flex-direction: column; '>
						<img2
							width='124'
							heigh='132'
							style='width: 124px; height: 132px; filter: grayscale(1)'
							src={require('../assets/img/invitied-account.png')}
						/>
						<div class='text__muted mt-5 ml-3 mr-3 text__center'>
							{this.$t('load_user_error')}
							<div>{$retry}</div>
						</div>
					</div>
				</div>
			)
		},

		async onUnlink() {
			this.userid = this.trueUserId
			await store.fetchUser(this.userid, true)
			// try to get updated user
			this.refresh = !this.refresh
		},

		async onMerge() {
			await store.fetchUser(this.trueUserId, true)
			let user = store.matchUser(this.trueUserId, true)
			if (user && user.primary_id) this.userid = user.primary_id
			this.refresh = !this.refresh
		},
	},

	render() {
		if (this.refresh) lo.noop()
		// render first user if uid is empty

		let uid = this.userid
		let cid = this.convoid

		let user = store.matchUser(uid)
		uid = lo.get(user, 'id') || uid

		if (!uid || uid === '-') {
			if (!cid || cid == '-') user = undefined
		}

		if (this.error) return this.renderError()
		let $padder = <div style='width: 1000px'></div>

		let isAccessDeny = lo.get(user, 'error')
		if (isAccessDeny) {
			return (
				<div class='profile'>
					<div class='profile_error_container'>
						<div class='text__center text__secondary'>
							<Icon name='lock' size='40' stroke-width='1' />
							<div class='mt-3'>{this.$t('you_dont_have_permission_to_view_other_lead')}</div>
							<div class=''>{this.$t('you_dont_have_permission_to_view_other_lead_cta_text')}</div>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div class={{profile: true}} vOn:click={() => this.$emit('changeConvoMode', 'activity')}>
				{this.customer_header && <div class='ml-4 mt-3 text__semibold'>{this.$t('customer')}</div>}

				{$padder}
				<UserInformation
					refresh={this.refresh}
					call_entry={this.call_entry}
					user={user}
					true_user_id={this.trueUserId}
					vOn:ucidChange={(uid, cid, isEditorFocused) => this.$emit('ucidChange', uid, cid, isEditorFocused)}
					vOn:showNewConvo={(p) => this.$emit('showNewConvo', p)}
					vOn:unlink={this.onUnlink}
					vOn:merge={this.onMerge}
				/>
				<UserLabels uid={lo.get(user, 'id')} refresh={this.refresh} />
				<UserLeadOwner user={user} refresh={this.refresh} />

				<UserTasks user={user} />

				{store.hasTicketFeature() && (
					<UserTickets uid={this.trueUserId} cid={cid} vOn:ticketSelect={this.onTicketSelet} />
				)}

				<UserNotes user={user} />
				<UserOrders user={user} cid={cid} vOn:convoSelect={this.convoSelect} />
				<div ref='user_conversation_history'></div>
				<UserConversations
					refresh={this.refresh}
					uid={uid}
					cid={cid}
					vOn:convoSelect={this.convoSelect}
					dont_auto_select_convo={this.dont_auto_select_convo}
				/>
				<UserTraces refresh={this.refresh} user={user} true_user_id={this.trueUserId} />
			</div>
		)
	},
}
