const api = require('@sb/store/api.js')
const sb = require('@sb/util')
import store from '@sb/store'
import {OnelineMsg, AvatarGroup} from '@sb/com'
import UserConvoItem from './user_convo_item.js'

const limit = 4

export default {
	name: 'user-convos',
	props: ['uid', 'cid', 'dont_auto_select_convo', 'no_title'],
	data() {
		return {
			conversations: [],
			showFull: false,
			loading: false,

			error: false,
			expandCallConvoIds: [],
		}
	},

	async mounted() {
		store.onConvo(this, (convoM) => {
			// somehow render loop in accid acsaxxqckewhjoxqgidu, http://localhost:8080/convo?uid=ussbalhdxvorirmgbyzuh&cid=cssbbpgijiudcozneb
			// dont forceUpdate onConvo.
			return
			if (!this.uid) return
			let forceupdate = false
			lo.map(convoM, (convo) => {
				let user = lo.find(convo.members, (mem) => mem.type === 'user' && mem.id === this.uid)
				if (user) forceupdate = true
			})
			forceupdate && this.$forceUpdate()
		})
		if (this.uid) this.loadConversations(this.uid)
	},

	watch: {
		uid(uid, olduid) {
			// stay cham 1 nhop
			//					this.loading = true
			this.showFull = false
			this.loadConversations(uid)
			this.expandCallConvoIds = []
		},
	},

	methods: {
		renderHead() {
			if (this.no_title) return null
			return (
				<div class='user_profile__head'>
					<div class='profile_section_title'>
						{this.$t('conversations_history')}
						{this.loading ? (
							<Spinner static mode='dark' />
						) : (
							<span class='user_profile_count_badge'>{lo.size(store.matchUserConvos(this.uid))}</span>
						)}
					</div>
				</div>
			)
		},

		toogleShowFull() {
			this.showFull = !this.showFull
		},

		renderHandle(convos) {
			if (this.loading) return null
			let more = convos.length - limit
			if (more <= 0) return null

			if (this.showFull) {
				return (
					<div class='profile_section__more ml-4 mr-4' vOn:click={this.toogleShowFull}>
						{this.$t('collapse')}
					</div>
				)
			}

			return (
				<div class='profile_section__more ml-4 mr-4' vOn:click={this.toogleShowFull}>
					{this.$t('show_more_convo', [more + 1])}
				</div>
			)
		},

		async loadConversations(userid) {
			let iid = this._loadid || 0
			iid++
			this._loadid = iid

			if (!userid) return
			let convos = lo.orderBy(store.matchUserConvos(this.uid), 'actived', 'desc')
			let defconvoid = this.getDefConvoId()

			if (!this.cid && !this.dont_auto_select_convo && defconvoid)
				this.$emit('convoSelect', defconvoid, {mounted: true})

			this.loading = true
			let {error} = await store.fetchUserConvos(userid)
			if (this._loadid != iid) return // outdated

			if (error) this.error = true
			else this.error = false
			this.loading = false

			// select the first convo
			if (!this.cid && !defconvoid && !this.dont_auto_select_convo) {
				defconvoid = this.getDefConvoId()
				//let convos = lo.orderBy(store.matchUserConvos(this.uid), 'actived', 'desc')
				//if (!convos[0] || !convos[0].id) return this.$emit('convoSelect', '')
				this.$emit('convoSelect', defconvoid, {mounted: true})
			}
		},

		getDefConvoId() {
			let convos = lo.orderBy(store.matchUserConvos(this.uid), 'actived', 'desc')
			let convo = lo.find(convos, (convo) => {
				let members = lo.get(convo, 'members', [])
				return lo.find(members, (mem) => mem.id === this.uid)
			})
			return lo.get(convo, 'id', '')
		},

		onClickConvo(id) {
			if (id === this.cid) return
			this.$emit('convoSelect', id)
		},

		expandCallConvo(id) {
			this.expandCallConvoIds = [...this.expandCallConvoIds, id]
		},

		toggleExpandConvo(cid) {
			if (!lo.includes(this.expandCallConvoIds, cid)) {
				this.expandCallConvoIds = [...this.expandCallConvoIds, cid]
				this.onClickConvo(cid)
			} else {
				this.expandCallConvoIds = lo.filter(this.expandCallConvoIds, (id) => id !== cid)
			}
		},

		renderCallConvoItem(convo) {
			let $text = ''
			let $icon = null
			let direction = lo.get(convo, 'call.direction')
			let isMissed = lo.get(convo, 'call.is_missed')
			let isExpandable = lo.size(convo._subconvos) > 0 && !isMissed
			let answeredAgent = lo.find(convo.members, (member) => member.type === 'agent' && member.call_answered) || {}
			answeredAgent = store.matchAgent()[answeredAgent.id] || {}
			let duration_sec = lo.get(convo, 'call.recorded_audio.duration', 0)
			if (!duration_sec) {
				let answered = lo.get(convo, 'call.answered', 0)
				let started = lo.get(convo, 'call.started', 0)
				let sec = lo.get(convo, 'call.duration_sec', 0)
				duration_sec = sec + answered - started
			}

			if (isMissed && direction === 'outbound') {
				$icon = (
					<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M3.75 3H6.75L8.25 6.75L6.375 7.875C7.17822 9.50365 8.49635 10.8218 10.125 11.625L11.25 9.75L15 11.25V14.25C15 14.6478 14.842 15.0294 14.5607 15.3107C14.2794 15.592 13.8978 15.75 13.5 15.75C10.5744 15.5722 7.81512 14.3299 5.74262 12.2574C3.67013 10.1849 2.42779 7.42555 2.25 4.5C2.25 4.10218 2.40804 3.72064 2.68934 3.43934C2.97064 3.15804 3.35218 3 3.75 3'
							fill='#929292'
						/>
						<path
							d='M11.25 6.75L15 3'
							stroke='#929292'
							stroke-width='1.2'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
						<path d='M12 3H15V6' stroke='#929292' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round' />
					</svg>
				)

				let missedreason = lo.get(convo, 'call.hangup_code')
				let reason = ''
				if (missedreason) reason = '(' + this.$t('hangup_' + missedreason) + ')'
				$text = (
					<div class='text__truncate' v-tooltip={reason}>
						{sb.getAgentDisplayName(answeredAgent) + ' ' + this.$t('call_to_customer').toLowerCase() + reason}
					</div>
				)
				if (lo.size(convo._subconvos)) {
					let lastCreated = lo.last(convo._subconvos)
					lastCreated = lo.get(lastCreated, 'created', 0)
					let duration = (convo.created || 0) - lastCreated
					duration = duration / 1000
					$text = (
						<div v-tooltip={reason}>
							{lo.size(convo._subconvos) + 1} {this.$t('failed_calls').toLowerCase()} {this.$t('in')}{' '}
							<Time duration time={duration} />
						</div>
					)
				}
			}

			if (isMissed && direction === 'inbound') {
				$icon = (
					<svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M1.52941 1H4.58824L6.11765 4.82353L4.20588 5.97059C5.02485 7.63117 6.36883 8.97515 8.02941 9.79412L9.17647 7.88235L13 9.41177V12.4706C13 12.8762 12.8389 13.2652 12.552 13.552C12.2652 13.8389 11.8762 14 11.4706 14C8.48767 13.8187 5.67424 12.552 3.5611 10.4389C1.44797 8.32577 0.181273 5.51233 0 2.52941C0 2.12379 0.161134 1.73477 0.447954 1.44795C0.734775 1.16113 1.12379 1 1.52941 1Z'
							fill='#FF3647'
						/>
						<path
							d='M11 1L15 5M15 1L11 5'
							stroke='#FF3647'
							stroke-width='1.2'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
					</svg>
				)

				$text = <div class='text__danger'>{this.$t('cus_call_failed')}</div>
				if (lo.size(convo._subconvos)) {
					let lastCreated = lo.last(convo._subconvos)
					lastCreated = lo.get(lastCreated, 'created', 0)
					let duration = (convo.created || 0) - lastCreated
					duration = duration / 1000
					$text = (
						<div class='text__danger'>
							{lo.size(convo._subconvos) + 1} {this.$t('missed_calls').toLowerCase()} {this.$t('in')}{' '}
							<Time duration time={duration} />
						</div>
					)
				}
			}

			if (!isMissed && direction === 'outbound') {
				$icon = (
					<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M3.75 3H6.75L8.25 6.75L6.375 7.875C7.17822 9.50365 8.49635 10.8218 10.125 11.625L11.25 9.75L15 11.25V14.25C15 14.6478 14.842 15.0294 14.5607 15.3107C14.2794 15.592 13.8978 15.75 13.5 15.75C10.5744 15.5722 7.81512 14.3299 5.74262 12.2574C3.67013 10.1849 2.42779 7.42555 2.25 4.5C2.25 4.10218 2.40804 3.72064 2.68934 3.43934C2.97064 3.15804 3.35218 3 3.75 3Z'
							fill='#19B600'
						/>
						<path
							d='M11.25 6.75L15 3'
							stroke='#19B600'
							stroke-width='1.2'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
						<path d='M12 3H15V6' stroke='#19B600' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round' />
					</svg>
				)

				$text = (
					<div class='d-flex align-items-center clickable'>
						{sb.getAgentDisplayName(answeredAgent)}&nbsp;{this.$t('call_to_customer').toLowerCase()}&nbsp;(
						{sb.displayClockTimer(duration_sec)})
					</div>
				)

				if (lo.size(convo._subconvos)) {
					$text = (
						<div class='d-flex align-items-center clickable' vOn:click={() => this.toggleExpandConvo(convo.id)}>
							{lo.size(convo._subconvos) + 1} {this.$t('outgoing_call').toLowerCase()}
							<Icon
								name={lo.includes(this.expandCallConvoIds, convo.id) ? 'chevron-up' : 'chevron-down'}
								size='14'
								class='text__muted ml-2 user_convo_item_call_icon'
							/>
						</div>
					)
				}
			}

			if (!isMissed && direction === 'inbound') {
				$icon = (
					<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M3.75 3H6.75L8.25 6.75L6.375 7.875C7.17822 9.50365 8.49635 10.8218 10.125 11.625L11.25 9.75L15 11.25V14.25C15 14.6478 14.842 15.0294 14.5607 15.3107C14.2794 15.592 13.8978 15.75 13.5 15.75C10.5744 15.5722 7.81512 14.3299 5.74262 12.2574C3.67013 10.1849 2.42779 7.42555 2.25 4.5C2.25 4.10218 2.40804 3.72064 2.68934 3.43934C2.97064 3.15804 3.35218 3 3.75 3'
							fill='#19B600'
						/>
						<path
							d='M11.25 6.75L15 3'
							stroke='#19B600'
							stroke-width='1.2'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
						<path
							d='M11.25 3.75V6.75H14.25'
							stroke='#19B600'
							stroke-width='1.2'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
					</svg>
				)

				$text = (
					<div class='d-flex align-items-center clickable'>
						{sb.getAgentDisplayName(answeredAgent)}&nbsp;{this.$t('answer').toLowerCase()}&nbsp;(
						{sb.displayClockTimer(duration_sec)})
					</div>
				)

				if (lo.size(convo._subconvos)) {
					$text = (
						<div class='d-flex align-items-center clickable' vOn:click={() => this.toggleExpandConvo(convo.id)}>
							{lo.size(convo._subconvos) + 1} {this.$t('incoming_call').toLowerCase()}
							<Icon
								name={lo.includes(this.expandCallConvoIds, convo.id) ? 'chevron-up' : 'chevron-down'}
								size='14'
								class='text__muted ml-2 user_convo_item_call_icon'
							/>
						</div>
					)
				}
			}

			let isactive = this.cid === convo.id
			lo.each(convo._subconvos, (subconvo) => {
				if (isactive) return false // break loop
				if (this.cid === subconvo.id) {
					isactive = true
					return false // break loop
				}
			})
			return (
				<div
					key={convo.id}
					class={`user_convo_item ${isactive ? 'active' : ''}`}
					style='cursor: pointer'
					vOn:click={(_) => !isExpandable && this.onClickConvo(convo.id)}
				>
					<div class='d-flex align-items-center'>
						{$icon}
						<div class='ml-3 mr-3 flex__1 text__truncate'>{$text}</div>
						<Time time={convo.actived || convo.created} class={`text__muted no-shrink`} style='font-size: 14px' />
					</div>
					{lo.includes(this.expandCallConvoIds, convo.id) && (
						<Fragment>
							{this.renderCallSubConvo(convo)}
							{lo.map(convo._subconvos, this.renderCallSubConvo)}
						</Fragment>
					)}
				</div>
			)
		},

		renderCallSubConvo(convo) {
			let isactive = this.cid === convo.id
			let text = ''
			let $icon = null
			let direction = lo.get(convo, 'call.direction')
			let answeredAgent = lo.find(convo.members, (member) => member.type === 'agent' && member.call_answered) || {}
			answeredAgent = store.matchAgent()[answeredAgent.id] || {}

			if (direction === 'outbound') {
				$icon = (
					<svg width='14' height='14' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M3.75 3H6.75L8.25 6.75L6.375 7.875C7.17822 9.50365 8.49635 10.8218 10.125 11.625L11.25 9.75L15 11.25V14.25C15 14.6478 14.842 15.0294 14.5607 15.3107C14.2794 15.592 13.8978 15.75 13.5 15.75C10.5744 15.5722 7.81512 14.3299 5.74262 12.2574C3.67013 10.1849 2.42779 7.42555 2.25 4.5C2.25 4.10218 2.40804 3.72064 2.68934 3.43934C2.97064 3.15804 3.35218 3 3.75 3Z'
							fill='#19B600'
						/>
						<path
							d='M11.25 6.75L15 3'
							stroke='#19B600'
							stroke-width='1.2'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
						<path d='M12 3H15V6' stroke='#19B600' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round' />
					</svg>
				)

				text = `${sb.getAgentDisplayName(answeredAgent)} ${this.$t('call_to_customer').toLowerCase()}`
			}

			if (direction === 'inbound') {
				$icon = (
					<svg width='14' height='14' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M3.75 3H6.75L8.25 6.75L6.375 7.875C7.17822 9.50365 8.49635 10.8218 10.125 11.625L11.25 9.75L15 11.25V14.25C15 14.6478 14.842 15.0294 14.5607 15.3107C14.2794 15.592 13.8978 15.75 13.5 15.75C10.5744 15.5722 7.81512 14.3299 5.74262 12.2574C3.67013 10.1849 2.42779 7.42555 2.25 4.5C2.25 4.10218 2.40804 3.72064 2.68934 3.43934C2.97064 3.15804 3.35218 3 3.75 3'
							fill='#19B600'
						/>
						<path
							d='M11.25 6.75L15 3'
							stroke='#19B600'
							stroke-width='1.2'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
						<path
							d='M11.25 3.75V6.75H14.25'
							stroke='#19B600'
							stroke-width='1.2'
							stroke-linecap='round'
							stroke-linejoin='round'
						/>
					</svg>
				)

				text = `${sb.getAgentDisplayName(answeredAgent)} ${this.$t('answer').toLowerCase()}`
			}
			let duration_sec = lo.get(convo, 'call.recorded_audio.duration', 0)
			if (!duration_sec) {
				let answered = lo.get(convo, 'call.answered', 0)
				let started = lo.get(convo, 'call.started', 0)
				let sec = lo.get(convo, 'call.duration_sec', 0)
				duration_sec = sec + answered - started
			}

			return (
				<div
					class='d-flex align-items-center'
					style='margin-left: 30px; margin-top: 2px; font-size: 14px;'
					vOn:click_stop={() => this.onClickConvo(convo.id)}
				>
					{$icon}
					<div class={{'ml-3': true, text__bold: isactive}}>{text}</div>
					<div class={{'ml-2': true, text__muted: true, text__bold: isactive}}>
						({sb.displayClockTimer(duration_sec)})
					</div>
				</div>
			)
		},

		renderConvos(convos) {
			// trim the list
			if (!this.showFull && convos.length > limit) convos = lo.take(convos, limit - 1)

			let $convos = lo.map(convos, (convo) => {
				convo = store.matchConvo(convo.id) || convo
				if (lo.get(convo, 'touchpoint.channel') === 'call') return this.renderCallConvoItem(convo)
				return <UserConvoItem convo={convo} cid={this.cid} vOn:click={(cid) => this.onClickConvo(cid)} />
			})

			let $empty = null
			if (this.loading) {
				return (
					<div class='text__muted mt-2' style='margin-left: 20px;'>
						<Spinner class='ml-2' static size='16' mode='blue' />
					</div>
				)
			}

			if (lo.size(convos) === 0 && !this.loading) {
				$empty = (
					<div class='text__muted mt-2' style='margin-left: 20px;'>
						{this.$t('no_conversation')}
					</div>
				)
			}

			if (this.error) {
				let $tryAgainBtn = (
					<div class='ml-2 link' vOn:click={(_) => this.loadConversations(this.uid)}>
						{this.$t('try_again')}
					</div>
				)
				if (this.loading) $tryAgainBtn = <Spinner class='ml-2' size='16' mode='blue' />
				return (
					<div class='user_convos__body'>
						<div class='text__muted d-flex align-items-center'>
							<Icon name='alert-triangle' style='margin-top:-4px' class='mr-2 text__warning' size='18' />{' '}
							{this.$t('load_user_convos_error')}. {$tryAgainBtn}
						</div>
					</div>
				)
			}

			return (
				<fragment>
					<div class='user_convos__body'>{$convos}</div>
					{$empty}
				</fragment>
			)
		},
	},

	render() {
		if (!this.uid) return null

		let allconvos = lo.map(store.matchUserConvos(this.uid), (convo) => {
			if (!convo.actived) convo.actived = convo.created
			return convo
		})

		allconvos = lo.orderBy(store.matchUserConvos(this.uid), 'actived', 'desc')

		let groupedConvos = makeGroupedCallConvos(allconvos)
		return (
			<div class='user_convos'>
				{this.renderHead()}
				{this.renderConvos(groupedConvos)}
				{this.renderHandle(groupedConvos)}
			</div>
		)
	},
}

// group convo with same direct, same missed status, not over 24h and continuesly
function makeGroupedCallConvos(convos) {
	let mainCallConvo = null

	let result = []
	lo.each(convos, (convo) => {
		convo._subconvos = []
		if (lo.get(convo, 'touchpoint.channel') !== 'call') {
			result.push(convo)
			mainCallConvo = null
			return
		}

		if (isMatchMainConvo(convo, mainCallConvo)) {
			let resultconvo = lo.find(result, (rconvo) => rconvo.id === lo.get(mainCallConvo, 'id'))
			if (!resultconvo) return // continue loop

			resultconvo._subconvos.push(convo)
		} else {
			result.push(convo)
			mainCallConvo = convo
		}
	})

	return result
}

function isMatchMainConvo(convo, mainConvo) {
	let inTime = lo.get(mainConvo, 'created', 0) - lo.get(convo, 'created') <= 24 * 3600 * 1000
	let sameDirection = lo.get(convo, 'call.direction') === lo.get(mainConvo, 'call.direction')
	let sameStatus = lo.get(convo, 'call.is_missed') === lo.get(mainConvo, 'call.is_missed')
	return inTime && sameStatus && sameDirection
}
