var sb = require('@sb/util')

export default {
	name: 'oneline-message',
	props: ['ev', 'placeholder', 'hasAuthor', 'convo'],

	render() {
		let ev = this.ev

		if (!ev) return <div>{this.placeholder}</div>
		var msg = lo.get(ev, 'data.message', {})

		var hasFrom = sb.getMsgField(ev, 'from')
		var hasTo = sb.getMsgField(ev, 'to')
		if (hasFrom && hasTo) return <div>{html2Text(msg.text)}</div>

		var nAtts = lo.size(msg.attachments)
		if (!msg.text && nAtts === 0) return <div>{this.placeholder}</div> // empty msg

		let sendername = ''

		let isInstaPost = lo.get(this.convo, 'touchpoint.channel') === 'instagram_comment'
		let isUserSent = lo.get(ev, 'by.type') === 'user'
		let isConvoEnded = lo.get(this.convo, 'state') === 'ended'

		let isTextBold = isUserSent && !isConvoEnded

		if (lo.get(ev, 'by.type') === 'user') {
			if (lo.get(this, 'convo.state') === 'ended') {
				sendername = (
					<span>
						{this.$t('guest')}
						{': '}
					</span>
				)
				if (isInstaPost) {
					sendername = (
						<span>
							<span class='text__dark'>{this.$t('comment')}</span>
							{': '}
						</span>
					)
				}
			} else {
				sendername = (
					<span>
						<span>{this.$t('guest')}</span>
						{': '}
					</span>
				)
				if (isInstaPost) {
					sendername = (
						<span>
							<span class='text__dark'>{this.$t('comment')}</span>
							{': '}
						</span>
					)
				}
			}
		} else if (lo.get(ev, 'by.type') === 'agent') {
			// should replace with subiz logo
			let agent = store.matchAgent()[ev.by.id]
			sendername = (sb.getAgentDisplayName(agent) + '').split(' ')[0] + ': '
		} else if (lo.get(ev, 'by.type') === 'workflow') {
			let automation = store.matchConvoAutomation()[ev.by.id] || {}
			sendername = automation.name || 'Workflow'
			sendername += ': '
		} else {
			sendername = 'Agent: '
		}

		if (store.me().id === lo.get(ev, 'by.id')) sendername = this.$t('me') + ': '
		if (!this.hasAuthor) sendername = ''

		// if has text and 0 attachment => first line of text
		if (msg.text && nAtts === 0) {
			if (msg.format === 'html') {
				return (
					<div style={isTextBold ? 'font-weight: 700' : ''}>
						{sendername}
						{html2Text(msg.text)}
					</div>
				)
			}

			var text = lo.trim(msg.text)
			let block = msg.block
			if (!block && msg.format === 'delta') {
				let text = lo.get(this.ev, 'data.message.quill_delta', '')
				if (!text) text = lo.get(this.ev, 'data.message.text')
				block = sb.deltaToBlock(text)
			}
			if (block) text = sb.blockToText(block)

			text = text.split('\n').join('. ')

			var $tags = sb.extractText(text).map((tag) => {
				if (tag.type === 'emoji') return <Emoji emojiCode={tag.text} />
				return tag.text
			})

			return (
				<div style={isTextBold ? 'font-weight: 700' : ''}>
					{sendername}
					{$tags}
				</div>
			)
		}

		// if has multiple attachment || has text and ? attachment
		if (nAtts > 2 || (msg.text && nAtts > 0)) {
			return (
				<div style={isTextBold ? 'font-weight: 700' : ''}>
					{sendername}
					{this.$t('sent_a_message_with') + ' ' + nAtts + ' ' + this.$t('attachments')}
				</div>
			)
		}

		// no text, just one attachment
		var mimetype = lo.get(msg, 'attachments.0.mimetype')
		if (lo.includes(mimetype, 'audio')) {
			return (
				<div style={isTextBold ? 'font-weight: 700' : ''}>
					{sendername}
					{this.$t('sent_an_audio')}
				</div>
			)
		}
		if (lo.includes(mimetype, 'video')) {
			return (
				<div style={isTextBold ? 'font-weight: 700' : ''}>
					{sendername}
					{this.$t('sent_a_video')}
				</div>
			)
		}
		if (lo.includes(mimetype, 'image')) {
			return (
				<div style={isTextBold ? 'font-weight: 700' : ''}>
					{sendername}
					{this.$t('sent_a_picture')}
				</div>
			)
		}
		return (
			<div style={isTextBold ? 'font-weight: 700' : ''}>
				{sendername}
				{this.$t('sent_an_attachment')}
			</div>
		)
	},
}

function html2Text(html) {
	let doc = new DOMParser().parseFromString(html, 'text/html')
	return doc.body.textContent || ''
}
